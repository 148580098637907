<template>
	<v-layout v-if="selectedAccountingFirm && (isInternalVideoconference || selectedCompany)" fill-height>
		<v-flex v-if="!showVideoConference">
			<v-layout fill-height justify-center align-center>
				<v-btn color="primary" @click="showVideoConference = true">
					{{ $t('videoconference.join_room', { roomName: roomName }) }}
				</v-btn>
			</v-layout>
		</v-flex>
	</v-layout>
</template>
<script>
import { mapState } from 'vuex'
import VideoConferenceModuleGuard from '@/mixins/ModulesGuards/VideoConference/VideoConferenceModuleGuard'

export default {
	name: 'VideoConferenceRoom',
	mixins: [VideoConferenceModuleGuard],
	data: function () {
		return {
			additionalScripts: [],
			api: null,
			showVideoConference: false,
			isInternalVideoconference: false
		}
	},
	computed: {
		...mapState({
			selectedCompany: state => state.company.selected,
			selectedAccountingFirm: state => state.accountingFirm.selected,
			loggedInUserEmailAddress: state => state.user.email,
			loggedInUserFirstname: state => state.user.firstname,
			loggedInUserLastname: state => state.user.lastname,
			dark: state => state.user.darkMode
		}),
		roomName: function () {
			let result = this.selectedAccountingFirm.name
			if (this.isInternalVideoconference) {
				result += ' '
				result += this.$t('videoconference.internal_suffix')
			} else {
				result += ' X '
				result += this.selectedCompany.company
			}
			return result
		},
		roomTechName: function () {
			return this.isInternalVideoconference
				? `wng_${this.selectedAccountingFirm.id}-internal`
				: `wng_${this.selectedAccountingFirm.id}-${this.selectedCompany?.id}`
		}
	},
	watch: {
		vendorId: {
			handler: function (newVal) {
				if (!newVal) {
					this.selectInternalVideoConference()
				} else {
					this.isInternalVideoconference = false
					this.destroyVideoconference()
					this.$nextTick(() => {
						this.createVideoConference()
					})
				}
			},
			immediate: true
		},
		showVideoConference: {
			deep: false,
			handler: function (newValue) {
				if (newValue) {
					this.createVideoConference()
				} else {
					this.destroyVideoconference()
				}
			}
		}
	},
	created: function () {
		const script = document.createElement('script')
		script.src = this.$envVar.VUE_APP_JITSI_MEET_SCRIPT_URL
		document.body.prepend(script)
		this.additionalScripts.push(script)
	},
	destroyed: function () {
		this.additionalScripts.map(additionalScript => document.body.removeChild(additionalScript))
	},
	methods: {
		selectInternalVideoConference: function () {
			this.isInternalVideoconference = true
		},
		createVideoConference: function (passed) {
			try {
				if (this.showVideoConference && this.selectedAccountingFirm && this.selectedCompany) {
					const domain = `${this.$envVar.VUE_APP_JITSI_MEET_SERVER}/`
					const options = {
						roomName: escape(this.roomTechName),
						width: '100%',
						height: '100%',
						parentNode: this.$el,
						configOverwrite: {
							liveStreamingEnabled: false,
							enableWelcomePage: false,
							enableClosePage: true,
							defaultLanguage: 'fr',
							disableProfile: true,
							doNotStoreRoom: true,
							backgroundImageUrl: '_blank',
							logoClickUrl: '_blank',
							logoImageUrl: '_blank',
							dynamicBrandingUrl: '',
							websocket: 'wss://test2.dist.welyb.com/xmpp-websocket'
						},
						interfaceConfigOverwrite: {
							DEFAULT_BACKGROUND: this.dark ? '#474747' : '#FFFFFF',
							ENABLE_FEEDBACK_ANIMATION: true,
							BRAND_WATERMARK_LINK: `https://${this.selectedAccountingFirm.domain}`,
							JITSI_WATERMARK_LINK: `https://${this.selectedAccountingFirm.domain}`
						},
						userInfo: {
							email: this.loggedInUserEmailAddress,
							displayName: `${this.loggedInUserFirstname} ${this.loggedInUserLastname}`
						},
						subject: this.roomName
					}

					// eslint-disable-next-line no-undef
					this.api = new JitsiMeetExternalAPI(domain, options)
					this.api.executeCommand('subject', this.roomName)
					this.api.addEventListener('readyToClose', () => {
						this.showVideoConference = false
					})
				}
			} catch (e) {
				if (passed) {
					throw e
				}

				this.destroyVideoconference()
				this.$nextTick(() => {
					this.createVideoConference(true)
				})
			}
		},
		destroyVideoconference: function () {
			if (this.api) {
				this.api.dispose()
			}
			this.api = null
		}
	}
}
</script>
